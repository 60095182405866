var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center"},[_c('h1',[_vm._v("Users")]),_c('b-col',{attrs:{"md":"12"}},[_c('datatable',{ref:"datatable",attrs:{"classe":"table table-bordered table-striped","url":_vm.datatableUrl,"btnExport":false},on:{"create":function($event){return _vm.$router.push({ name: 'users-create' })}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var actions = ref.actions;
return _c('div',{},[_c('b-dropdown',{attrs:{"boundary":"viewport","size":"sm","text":"Actions","variant":"primary"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({
                name: 'users-edit',
                params: { id: actions.id },
              })}}},[_c('i',{staticClass:"fa fa-edit p-1"}),_vm._v(" Edit ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.changeStatus(actions.status, actions.id, actions.enabled_items)}}},[_c('i',{staticClass:"fa fa-toggle-on p-1"}),_vm._v(" Change Status ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.resendEmail(actions.id)}}},[_c('i',{staticClass:"fa fa-send-o p-1"}),_vm._v(" Resend Email Validate ")])],1)],1)}},{key:"status",fn:function(ref){
              var data = ref.data;
return [_c('small',{class:'badge badge-' + (data == 'E' ? 'primary' : 'danger')},[_vm._v(" "+_vm._s(data == "E" ? "Enabled" : "Disabled")+" ")])]}},{key:"image",fn:function(ref){
              var data = ref.data;
return [_c('img',{staticClass:"rounded",attrs:{"src":_vm.resource(data),"width":"70","alt":data}})]}},{key:"roles",fn:function(ref){
              var data = ref.data;
return [(data.length)?_vm._l((data),function(item,index){return _c('small',{key:index,staticClass:"badge badge-danger mr-1"},[_vm._v(" "+_vm._s(item)+" ")])}):[_vm._v(" - ")]]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }